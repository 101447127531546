<template>
  <div class="containerLabsPage relative pt-4 w-100">
    <custom-scrollbar v-if="!isLoading">
      <b-container fluid>
        <div class="h3 mb-4">
          Модерация полисов
        </div>
        <div class="d-flex justify-content-between align-items-center">
          <b-form-select
            v-model="selectedStatus"
            :options="statusOptions"
            class="w-25"
            @change="fetchModerationList"
          />
        </div>
        <div
          v-if="list.length"
          class="mt-4 mb-4 flex items-center gap-3 title-16-medium-500"
        >
          <span>Поиск: </span>
          <b-input-group class="bg-white rounded">
            <b-form-input
              v-model="patientPhone"
              placeholder="Введите номер телефона пациента"
            />
            <b-form-input
              v-model="patientFio"
              placeholder="Введите ФИО пациента"
            />
          </b-input-group>
          <b-button
            v-if="patientPhone || patientFio"
            class="mr-2"
            size="sm"
            variant="outline-primary"
            :type="$const.PRIMARY_BUTTON"
          >
            <v-icon
              name="m/cross"
              class="z-50"
              @click="clearSearch"
            />
          </b-button>
        </div>
        <div v-if="list.length">
          <b-table
            :items="getList"
            :fields="fields"
            :per-page="0"
            :current-page="currentPage"
            bordered
            hover
          >
            <template v-slot:cell(moderatedAt)="row">
              <span>{{ createDateFormat(row) }}</span>
            </template>
            <template v-slot:cell(moderationStatus)="row">
              <span>{{ moderationStatus(row) }}</span>
            </template>
            <template v-slot:cell(actions)="row">
              <router-link :to="`/policy-moderation/${row.item.id}`">
                <b-button
                  class="mr-2"
                  size="sm"
                  variant="primary"
                  :type="$const.PRIMARY_BUTTON"
                >
                  Инфо
                </b-button>
              </router-link>
            </template>
          </b-table>
          <b-pagination
            v-if="isShowPagination"
            v-model="currentPage"
            :per-page="perPage"
            :total-rows="+allCount"
            pills
            style="padding-top: 16px"
            @input="onClickNextPage"
          />
        </div>

        <div
          v-else
          class="w-full p-8 flex justify-center"
        >
          {{ noPolices }}
        </div>
      </b-container>
    </custom-scrollbar>
    <div
      v-if="isLoading"
      class="absolute top-0 left-0 w-full h-full flex justify-center items-center bg-v-ui-root-monochrome-10"
    >
      <BSpinner />
    </div>
  </div>
</template>

<script>
import { format } from '@evd3v/date-fns';
import PolicyServices from '@/services/policy/policy.service';
import {
  POLICY_FIELDS,
  MODERATION_STATUS,
} from '@/services/policy/policy.consts';
import BSpinner from '@/components/base/BaseSpinner';
import { VIcon } from '@doctis.front/doctis.designsystem';

const policyServices = new PolicyServices();

export default {
  components: {
    BSpinner,
    VIcon,
  },
  data() {
    return {
      patientPhone: '',
      patientFio: '',
      fields: POLICY_FIELDS,
      list: [],
      currentPage: 1,
      perPage: 10,
      allCount: null,
      isLoading: false,
      selectedStatus: null,
      statusOptions: MODERATION_STATUS,
    };
  },
  computed: {
    getList() {
      if (this.list.length) {
        const filteredList = this.list.filter(
          (item) => item?.patientPhoneNumber.includes(this.patientPhone)
            && item?.patientFio.toLocaleLowerCase().includes(this.patientFio.toLocaleLowerCase()),
        );
        const to = this.perPage * this.currentPage;
        const from = to - this.perPage;
        const paginatedList = filteredList.slice(from, to);
        return paginatedList;
      }
      return [];
    },
    isShowPagination() {
      return this.list.length > this.perPage;
    },
    noPolices() {
      switch (this.selectedStatus) {
        case MODERATION_STATUS[1].key:
          return 'Нет одобренных полисов';

        case MODERATION_STATUS[2].key:
          return 'Нет отклоненных полисов';

        default:
          return 'Нет полисов, требующих модерации';
      }
    },
  },
  async created() {
    await this.fetchModerationList();
  },
  methods: {
    createDateFormat(row) {
      return format(new Date(row.item.createDate), 'dd.LL.yyyy');
    },
    moderationStatus(row) {
      return MODERATION_STATUS[row.item.moderationStatus].text;
    },
    clearSearch() {
      this.patientPhone = '';
      this.patientFio = '';
    },
    onClickNextPage(value) {
      this.currentPage = value;
    },
    async fetchModerationList() {
      const { query: { status } } = this.$route;
      if (!this.selectedStatus) this.selectedStatus = status || MODERATION_STATUS[0].key;
      if (status !== this.selectedStatus) this.$router.push({ query: { status: this.selectedStatus } });
      try {
        this.isLoading = true;
        const params = {
          Status: this.selectedStatus,
        };
        const { data } = await policyServices.moderationList(params);
        this.list = data;
        this.allCount = this.list.length;
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>
